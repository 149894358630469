import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IMockEnvironment } from './../interfaces/environment.interface';
import { mockAuthRes } from './../mock/mock-auth-res';
import { mockCountriesRes } from './../mock/mock-countries-res';
import { mockNewsRes } from './../mock/mock-news-res';
import { mockOpportunitiesRes } from './../mock/mock-opportunities-res';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const mockEnv: IMockEnvironment = environment.mock;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(
                mergeMap(() => {
                    /* AUTH */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.auth)) &&
                        (request.url.includes(SubUrlsEnum.LOGIN) ||
                            request.url.includes(SubUrlsEnum.REFRESH) ||
                            request.url.includes(SubUrlsEnum.USER) ||
                            request.url.includes(SubUrlsEnum.CONTACT_ME))
                    ) {
                        return mockAuthRes(request);
                    }

                    /* COUNTRIES */
                    if ((mockEnv.all || (mockEnv.services && mockEnv.services.countries)) && request.url.includes(SubUrlsEnum.COUNTRIES)) {
                        return mockCountriesRes(request);
                    }

                    /* OPPORTUNITIES */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.opportunities)) &&
                        request.url.includes(SubUrlsEnum.OPPORTUNTIES)
                    ) {
                        return mockOpportunitiesRes(request);
                    }

                    /* NEWS */
                    if ((mockEnv.all || (mockEnv.services && mockEnv.services.news)) && request.url.includes(SubUrlsEnum.NEWS)) {
                        return mockNewsRes(request);
                    }

                    // pass through any requests not handled above
                    return next.handle(request);
                })
            )
            .pipe(
                // call materialize and dematerialize to ensure delay even if an error is thrown
                // (cfr: https://github.com/Reactive-Extensions/RxJS/issues/648)
                materialize(),
                delay(200),
                dematerialize()
            );
    }
}

export const mockInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: MockInterceptor,
    multi: true,
};
