import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CountriesService } from '../../services/countries.service';
import { Countries } from '../actions/countries.actions';
import { ICountry } from './../../interfaces/country.interface';
import { News } from './../actions/news.actions';

export interface CountriesStateModel {
    countries: ICountry[];
    current: ICountry;
}

@State<CountriesStateModel>({
    name: 'countries',
    defaults: {
        countries: [],
        current: null,
    },
})
@Injectable()
export class CountriesState {
    @Selector()
    public static countries(state: CountriesStateModel): ICountry[] {
        return state.countries;
    }

    @Selector()
    public static current(state: CountriesStateModel): ICountry {
        return state.current;
    }

    constructor(private countriesService: CountriesService) {}

    @Action(Countries.GetAll)
    public getAll(ctx: StateContext<CountriesStateModel>): Observable<ICountry[]> {
        const state = ctx.getState();
        if (state.countries.length > 0) {
            return of(state.countries);
        }
        return this.countriesService.getAll().pipe(
            tap((result: ICountry[]) => {
                ctx.patchState({
                    countries: result.sort((c1: ICountry, c2: ICountry) => {
                        if (c1.name < c2.name) return -1;
                        if (c1.name > c2.name) return 1;
                        else return 0;
                    }),
                });
            })
        );
    }

    @Action(Countries.GetOne)
    public getOne(ctx: StateContext<CountriesStateModel>, action: News.GetOne): Observable<ICountry> {
        const state = ctx.getState();
        if (state.countries.length > 0) {
            const country = state.countries.find((c) => c.id === action.id);
            if (country) {
                ctx.patchState({
                    current: country,
                });
                return of(country);
            }
        }
        return this.countriesService.getAll().pipe(
            tap((result: ICountry[]) => {
                ctx.patchState({
                    current: result.find((c: ICountry) => c.id === action.id),
                });
            }),
            map((result: ICountry[]) => result.find((c: ICountry) => c.id === action.id))
        );
    }
}
