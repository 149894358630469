import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from './../../environments/environment';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { INews, INewsDtoIn, IPostDtoIn, IPosts } from './../interfaces/news.interface';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    constructor(private http: HttpClient) {}

    public getAll(page: number): Observable<IPosts> {
        return this.http.get<INewsDtoIn>(environment.api + SubUrlsEnum.NEWS + '?page=' + page + '&per=5').pipe(
            map((result: any) => ({
                ...result,
                posts: result.posts.map((p: IPostDtoIn) => this.toPost(p)),
            }))
        );
    }

    public getOne(id: number): Observable<INews> {
        return this.http.get<INews>(environment.api + SubUrlsEnum.NEWS + '/' + id).pipe(map((result: any) => this.toPost(result.post)));
    }

    private toPost(post: IPostDtoIn): INews {
        return {
            ...post,
            updated_at: post.updated_at ? new Date(post.updated_at) : null,
            created_at: post.created_at ? new Date(post.created_at) : null,
            deleted_at: post.deleted_at ? new Date(post.deleted_at) : null,
        };
    }
}
