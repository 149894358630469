import { state } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ILogin, ITokens } from '../../interfaces/auth.interface';
import { AuthService } from '../../services/auth.service';
import {
    ContactMeToChangeInfo,
    GetProfile,
    Login,
    Logout,
    RefreshToken,
    SetTokens,
    UpdateUserSettings,
} from '../actions/auth.actions';
import { Countries } from '../actions/countries.actions';
import { Opportunities } from '../actions/opportunities.actions';
import { IAccount } from './../../interfaces/account.interface';

export interface AuthStateModel {
    isAuth: boolean;
    refreshToken: string;
    accessToken: string;
    user: IAccount;
}

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        isAuth: false,
        accessToken: '',
        refreshToken: '',
        user: null,
    },
})
@Injectable()
export class AuthState {
    @Selector()
    public static isAuthenticated(state: AuthStateModel): boolean {
        return state.isAuth;
    }

    @Selector()
    public static accessToken(state: AuthStateModel): string {
        return state.accessToken;
    }

    @Selector()
    public static refreshToken(state: AuthStateModel): string {
        return state.refreshToken;
    }

    @Selector()
    public static user(state: AuthStateModel): IAccount {
        return state.user;
    }

    constructor(private authService: AuthService) {}

    @Action(Login)
    public login(ctx: StateContext<AuthStateModel>, action: Login): Observable<ILogin> {
        return this.authService.login(action.login, action.password).pipe(
            tap((result: ILogin) => {
                ctx.patchState({
                    isAuth: true,
                    user: result.user,
                    accessToken: result.token,
                    refreshToken: result.refresh_token,
                });
                ctx.dispatch([new Countries.GetAll(), new Opportunities.GetAll({ page: null, sort: null })]);
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    @Action(Logout)
    public logout(ctx: StateContext<AuthStateModel>): void {
        this.authService.logout();
        ctx.patchState({
            isAuth: false,
            user: null,
            accessToken: '',
            refreshToken: '',
        });
    }

    @Action(RefreshToken)
    public refreshToken(ctx: StateContext<AuthStateModel>): Observable<ITokens> {
        const state: AuthStateModel = ctx.getState();

        if (!state.refreshToken) {
            ctx.patchState({
                accessToken: null,
                isAuth: false,
            });
            return null;
        }

        return this.authService.refreshToken(state.refreshToken).pipe(
            tap((result: ITokens) => {
                ctx.patchState({
                    refreshToken: result.refresh_token,
                    accessToken: result.token,
                    isAuth: true,
                });
                ctx.dispatch([new Countries.GetAll(), new Opportunities.GetAll({ page: null, sort: null })]);
            }),
            catchError((err: HttpErrorResponse) => {
                ctx.patchState({
                    refreshToken: null,
                    accessToken: null,
                    isAuth: false,
                });
                return of(null);
            })
        );
    }

    @Action(GetProfile)
    public getProfile(ctx: StateContext<AuthStateModel>): Observable<IAccount> {
        return this.authService.getAccount().pipe(
            tap((result: IAccount) => {
                ctx.patchState({
                    user: result,
                });
            }),
            catchError((err: HttpErrorResponse) => {
                ctx.patchState({
                    user: null,
                });
                return of(null);
            })
        );
    }

    @Action(SetTokens)
    public setTokens(ctx: StateContext<AuthStateModel>, action: SetTokens): void {
        ctx.patchState({
            accessToken: action.accessToken,
            refreshToken: action.refreshToken,
        });
    }

    @Action(UpdateUserSettings)
    public updateUserSettings(ctx: StateContext<AuthStateModel>, action: UpdateUserSettings): Observable<IAccount> {
        return this.authService.updateAccountSettings(action.settings).pipe(
            tap((result: IAccount) => {
                ctx.patchState({
                    user: result,
                });
            })
        );
    }

    @Action(ContactMeToChangeInfo)
    public contactMeToChangeInfo(ctx: StateContext<AuthStateModel>): Observable<void> {
        return this.authService.contactMeToChangeInfo();
    }
}
