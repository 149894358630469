export enum SubUrlsEnum {
    // AUTH
    LOGIN = '/sessions',
    LOGOUT = '/sessions',
    REFRESH = '/sessions/renew_token',
    RESET_PWD = '/users/reset_password',

    // OPPORTUNITIES
    OPPORTUNTIES = '/opportunities',
    OPPORTUNTIES_PARTICIPATE = '/opportunities/{id}/participate',
    OPPORTUNTIES_MORE_INFO = '/opportunities/{id}/information',
    FAVORITES = '/bookmarks',
    MARK_AS_VIEWED = '/hits',

    // NEWS
    NEWS = '/posts',

    // ACCOUNT
    USER = '/users',
    CONTACT_ME = '/users/change_data',

    // COUNTRIES
    COUNTRIES = '/opportunities/countries',
}
