import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { INavbarLink } from './../../../shared/interfaces/navbar.interface';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
    public config: INavbarLink[] = [
        {
            title: 'opportunities',
            route: '/opportunities',
            iconActive: 'bi-trophy',
            iconInactive: 'bi-trophy-fill',
        },
        {
            title: 'bookmarks',
            route: '/favorites',
            iconActive: 'bi-star',
            iconInactive: 'bi-star-fill',
        },
        {
            title: 'posts',
            route: '/news',
            iconActive: 'bi-newspaper',
            iconInactive: 'bi-newspaper',
        },
        {
            title: 'my_account',
            route: '/account',
            iconActive: 'bi-person',
            iconInactive: 'bi-person-fill',
        },
    ];

    constructor(private store: Store) {}
}
