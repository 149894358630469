<div
    class="alert alert--position-{{ index }}"
    [ngClass]="{
        active: message,
        'alert-success': message.type === types.SUCCESS,
        'alert-secondary': message.type === types.INFO,
        'alert-danger': message.type === types.ERROR,
        'alert-warning': message.type === types.WARNING
    }"
    role="alert"
>
    <p>
        {{ message.text | translate }}
    </p>
</div>
