import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { IOpportunities, IOpportunitiesDTOIn, IOpportunity, IOpportunityDTOIn } from '../interfaces/opportunities.interface';
import { IFavoritesDtoIn } from './../interfaces/opportunities.interface';

@Injectable({
    providedIn: 'root',
})
export class OpportunitiesService {
    constructor(private http: HttpClient, private store: Store) {}

    public getAll(payload: { page: number; sort: string }): Observable<IOpportunities> {
        let params = new HttpParams();
        if (payload.page && !payload.sort) {
            params = params.set('page', payload.page.toString());
            params = params.set('per', '5');
        }
        if (payload.sort) {
            params = params.set('sort', payload.sort);
        }
        return this.http
            .get<IOpportunitiesDTOIn>(environment.api + SubUrlsEnum.OPPORTUNTIES, { params })
            .pipe(map((result: IOpportunitiesDTOIn) => this.toOpportunities(result)));
    }

    public getOne(id: number): Observable<IOpportunity> {
        return this.http
            .get<IOpportunity>(environment.api + SubUrlsEnum.OPPORTUNTIES + '/' + id)
            .pipe(map((result: any) => this.toOpportunity(result.opportunity)));
    }

    public getAllFavorites(): Observable<IOpportunity[]> {
        return this.http
            .get<IFavoritesDtoIn>(environment.api + SubUrlsEnum.FAVORITES)
            .pipe(map((result: IFavoritesDtoIn) => this.toFavorites(result)));
    }

    public addToFavorite(id: number): Observable<void> {
        return this.http.post<void>(environment.api + SubUrlsEnum.FAVORITES + '/' + id, {});
    }

    public removeFromFavorite(id: number): Observable<void> {
        return this.http.delete<void>(environment.api + SubUrlsEnum.FAVORITES + '/' + id);
    }

    public participate(id: number, alone: boolean): Observable<IOpportunity> {
        return this.http
            .post<any>(environment.api + SubUrlsEnum.OPPORTUNTIES_PARTICIPATE.replace('{id}', id.toString()), {
                alone: alone ? 1 : 0,
            })
            .pipe(map((result) => this.toOpportunity(result.opportunity)));
    }

    public askMoreInformation(id: number, alone: boolean): Observable<void> {
        return this.http.post<void>(environment.api + SubUrlsEnum.OPPORTUNTIES_MORE_INFO.replace('{id}', id.toString()), {
            alone: alone ? 1 : 0,
        });
    }

    public markAsViewed(id: number): Observable<void> {
        // la gestion des vues est gérée dans la back
        // pour éviter qu'un seul user incrémente plusieurs fois le nb de vue d'une meme opportunity
        return this.http.post<void>(environment.api + SubUrlsEnum.MARK_AS_VIEWED + '/' + id, {});
    }

    private toOpportunities(result: IOpportunitiesDTOIn): IOpportunities {
        return {
            ...result,
            opportunities: result.opportunities.map((r: IOpportunityDTOIn) => this.toOpportunity(r)),
        };
    }

    private toFavorites(result: IFavoritesDtoIn): IOpportunity[] {
        return result.bookmarks.map((r: IOpportunityDTOIn) => this.toOpportunity(r));
    }

    private toOpportunity(r: IOpportunityDTOIn): IOpportunity {
        return {
            ...r,
            is_new: this.isNew(new Date(r.created_at), new Date(r.end_date)),
            countriesIds: r.countries,
            countries: [],
            end_date: new Date(r.end_date),
            updated_at: new Date(r.updated_at),
            created_at: new Date(r.created_at),
            deleted_at: new Date(r.deleted_at),
        };
    }

    private isNew(creationDate: Date, endDate: Date): boolean {
        const now = new Date().getTime();
        return Math.round((now - creationDate.getTime()) / (1000 * 3600 * 24)) <= 7 && now < endDate.getTime();
    }
}
