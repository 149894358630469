import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';

import { componentsShared } from './components';
import { AuthState } from './store/state/auth.state';
import { CountriesState } from './store/state/countries.state';
import { FeedbackMessageState } from './store/state/feedback-message.state';
import { LoaderState } from './store/state/loader.state';
import { NewsState } from './store/state/news.state';
import { OpportunitiesState } from './store/state/opportunities.state';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        LocalizeRouterModule,
        RouterModule,
        ReactiveFormsModule,
        NgxsModule.forFeature([OpportunitiesState, NewsState, AuthState, LoaderState, CountriesState, FeedbackMessageState]),
    ],
    providers: [],
    declarations: [...componentsShared],
    exports: [CommonModule, ...componentsShared, TranslateModule, LocalizeRouterModule],
})
export class SharedModule {}
