import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
    private subs: Subscription[] = [];

    ngOnDestroy(): void {
        this.subs.map((sub: Subscription) => sub.unsubscribe());
    }

    public set uns(sub: Subscription) {
        this.subs = [...this.subs, sub];
    }
}
