import { IAccountSettings } from '../../interfaces/account.interface';

export class Login {
    public static readonly type = '[Auth] Login';
    constructor(public login: string, public password: string) {}
}

export class Logout {
    public static readonly type = '[Auth] Logout';
}

export class RefreshToken {
    public static readonly type = '[Auth] Refresh Token';
}

export class GetProfile {
    public static readonly type = '[Auth] Get profile';
}

export class SetTokens {
    public static readonly type = '[Auth] Set Tokens';
    constructor(public refreshToken: string, public accessToken: string) {}
}

export class ResetPassword {
    public static readonly type = '[Auth] Reset password';
    constructor(public newPassword: string, public token?: string) {}
}

export class UpdateUserSettings {
    public static readonly type = '[Auth] Update user settings';
    constructor(public settings: IAccountSettings) {}
}

export class ContactMeToChangeInfo {
    public static readonly type = '[Auth] Contact me to change info';
}
