import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../environments/environment';
import { httpInterceptorProviders } from '../shared/interceptors';
import { mockInterceptorProvider } from '../shared/interceptors/mock.interceptor';
import { SharedModule } from './../shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { componentsApp } from './components';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader =>
    new TranslateHttpLoader(http, './assets/locales/', '.json?' + Math.random().toString(36).substr(2, 5));
@NgModule({
    declarations: [AppComponent, ...componentsApp],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxsModule.forRoot([], { developmentMode: !environment.production, selectorOptions: { suppressErrors: false } }),
        NgxsStoragePluginModule.forRoot({
            key: ['auth.accessToken', 'auth.refreshToken'],
        }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [environment.mock.enable ? [...httpInterceptorProviders, mockInterceptorProvider] : httpInterceptorProviders],
    bootstrap: [AppComponent],
})
export class AppModule {}
