import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BaseComponent } from '../shared/abstracts/base.abstract';
import { AuthState } from '../shared/store/state/auth.state';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
    @Select(AuthState.isAuthenticated) public isAuth$: Observable<boolean>;

    private url: string;

    constructor(private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.uns = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event: any) => {
            this.url = event.url;
        });
    }

    public get isLoginWrapper(): boolean {
        return this.url?.includes('/auth');
    }
}
