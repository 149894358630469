import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from './../../environments/environment';
import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { ICountry, ICountryDtoIn } from './../interfaces/country.interface';

@Injectable({
    providedIn: 'root',
})
export class CountriesService {
    constructor(private http: HttpClient) {}

    public getAll(): Observable<ICountry[]> {
        return this.http
            .get<ICountryDtoIn>(environment.api + SubUrlsEnum.COUNTRIES)
            .pipe(
                map((result: ICountryDtoIn) =>
                    result.countries ? result.countries.map((c: ICountry) => ({ ...c, name: c.name.toUpperCase() })) : []
                )
            );
    }
}
