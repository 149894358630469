import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SubUrlsEnum } from '../enums/sub-urls.enum';
import { HeaderErrorEnum } from '../interceptors/header-errors.enum';
import { ILogin, ITokens } from '../interfaces/auth.interface';
import { IAccount, IAccountSettings } from './../interfaces/account.interface';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private http: HttpClient) {}

    public getAccount(): Observable<IAccount> {
        return this.http.get<IAccount>(environment.api + SubUrlsEnum.USER).pipe(map((result: any) => result.user));
    }

    public login(email: string, password: string): Observable<ILogin> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');
        return this.http.post<ILogin>(environment.api + SubUrlsEnum.LOGIN, { email, password }, { headers });
    }

    public logout(): Observable<void> {
        return this.http.delete<void>(environment.api + SubUrlsEnum.LOGOUT);
    }

    public refreshToken(token: string): Observable<ITokens> {
        return this.http.post<ITokens>(environment.api + SubUrlsEnum.REFRESH, { refresh_token: token });
    }

    public updateAccountSettings(settings: IAccountSettings): Observable<IAccount> {
        return this.http.put<IAccount>(environment.api + SubUrlsEnum.USER, { user: settings }).pipe(map((result: any) => result.user));
    }

    public contactMeToChangeInfo(): Observable<void> {
        return this.http.post<void>(environment.api + SubUrlsEnum.CONTACT_ME, {});
    }

    public resetPassword(email: string): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');
        return this.http.post<any>(environment.api + SubUrlsEnum.RESET_PWD, { email }, { headers }).pipe(
            map((result: any) => {
                if (result.error) {
                    return throwError(result);
                }
                return !!result.success;
            })
        );
    }
}
