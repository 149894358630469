import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CacheMechanism, LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { TranslateService } from '@ngx-translate/core';

import { LanguageEnum } from '../shared/enums/languages.enum';
import { AuthGuard } from './../shared/guards/auth.guard';

/* LOCALIZE SETUP */

export const createLocalizeRouterHttpLoader = (
    translate: TranslateService,
    location: Location,
    settings: LocalizeRouterSettings,
    http: HttpClient
): LocalizeRouterHttpLoader => new LocalizeRouterHttpLoader(translate, location, settings, http);

export const defaultLanguage = (languages: string[], cachedLang?: string, browserLang?: string): string => {
    let result: string = LanguageEnum.EN;
    if (cachedLang && languages.indexOf(cachedLang) > -1) {
        result = cachedLang;
    } else if (browserLang && languages.indexOf(browserLang) > -1) {
        result = browserLang;
    }
    return result;
};

/* ROUTES SETUP */

const routes: Routes = [
    { path: '', redirectTo: '/opportunities', pathMatch: 'full' },
    {
        path: 'opportunities',
        loadChildren: () => import('../views/opportunities/opportunities.module').then((m: any) => m.OpportunitiesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'favorites',
        loadChildren: () => import('../views/favorites/favorites.module').then((m: any) => m.FavoritesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'news',
        loadChildren: () => import('../views/news/news.module').then((m: any) => m.NewsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'account',
        loadChildren: () => import('../views/account/account.module').then((m: any) => m.AccountModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'auth',
        loadChildren: () => import('../views/auth/auth.module').then((m: any) => m.AuthModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
        }),
        LocalizeRouterModule.forRoot(routes, {
            cacheMechanism: CacheMechanism.Cookie,
            cacheName: 'defaultLangTrade4u',
            alwaysSetPrefix: true,
            defaultLangFunction: defaultLanguage,
            parser: {
                provide: LocalizeParser,
                useFactory: createLocalizeRouterHttpLoader,
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
            },
        }),
    ],
    exports: [RouterModule, LocalizeRouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule {}
