import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ICountry } from './../interfaces/country.interface';
import { mockCountries } from './mock-data';

export const mockCountriesRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: ICountry[] = mockCountries;

    if (req.method === 'GET') {
        const res = {
            status: 200,
            body: { countries: data },
        };

        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};
