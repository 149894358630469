import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { GetProfile, RefreshToken } from '../store/actions/auth.actions';
import { AuthState, AuthStateModel } from '../store/state/auth.state';

@Injectable()
export class AuthGuard implements CanActivate {
    @Select(AuthState.isAuthenticated) public auth$: Observable<boolean>;

    constructor(private store: Store, private router: Router, private localize: LocalizeRouterService) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const isAuthenticated: boolean = this.store.selectSnapshot(AuthState.isAuthenticated);
        if (isAuthenticated) {
            return of(true);
        }

        return this.store.dispatch(new RefreshToken()).pipe(
            withLatestFrom(this.auth$),
            map(([, auth]: [AuthStateModel, boolean]) => {
                if (auth) {
                    this.store.dispatch(new GetProfile());
                    return true;
                } else {
                    this.router.navigate([this.localize.translateRoute('/auth/login')]);
                    return false;
                }
            })
        );
    }
}
