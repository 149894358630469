import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertTypeEnum } from '../enums/alert.enum';
import { FeedbackMessages } from '../store/actions/feedback-messages.actions';
import { HeaderErrorEnum } from './header-errors.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: any) => {
                if (request.headers.get(HeaderErrorEnum.OWN_ERROR) === 'true') {
                    return throwError(error);
                }
                if (error) {
                    this.store.dispatch(
                        new FeedbackMessages.Set({
                            text: 'notif_error',
                            type: AlertTypeEnum.ERROR,
                        })
                    );
                    return of(error);
                }
                return throwError(error);
            })
        );
    }
}
