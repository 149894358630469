import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { IOpportunity } from './../interfaces/opportunities.interface';
import { mockOpportunities } from './mock-data';

export const mockOpportunitiesRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: IOpportunity[] = mockOpportunities;

    if (req.method === 'GET') {
        const res = {
            status: 200,
            body: { opportunities: data },
        };

        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};
