import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { INews } from './../interfaces/news.interface';
import { mockNews } from './mock-data';

export const mockNewsRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const data: INews[] = mockNews;

    if (req.method === 'GET') {
        const res = {
            status: 200,
            body: { news: data },
        };

        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};
