export namespace Opportunities {
    export class GetAll {
        public static readonly type = '[opportunities] Get all';
        constructor(public payload: { page: number; sort: string }) {}
    }

    export class GetOne {
        public static readonly type = '[opportunities] Get one';
        constructor(public id: number) {}
    }

    export class AddToFavorite {
        public static readonly type = '[opportunities] add To Favorite';
        constructor(public id: number) {}
    }

    export class RemoveFromFavorite {
        public static readonly type = '[opportunities] remove From Favorite';
        constructor(public id: number) {}
    }

    export class GetNext {
        public static readonly type = '[opportunities] get next page';
    }

    export class GetAllFavorites {
        public static readonly type = '[opportunities] get all favorites';
    }

    export class Participate {
        public static readonly type = '[opportunities] participate';
        constructor(public payload: { id: number; alone: boolean }) {}
    }

    export class AskMoreInfo {
        public static readonly type = '[opportunities] ask more info';
        constructor(public payload: { id: number; alone: boolean }) {}
    }

    export class ResetSort {
        public static readonly type = '[opportunities] reset sort';
    }
}
