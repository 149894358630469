import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IPaginationSettings } from '../../interfaces/pagination.interface';
import { INews, IPosts } from './../../interfaces/news.interface';
import { NewsService } from './../../services/news.service';
import { News } from './../actions/news.actions';

export interface NewsStateModel {
    news: INews[];
    current: INews;
    paginationSettings: IPaginationSettings;
}

@State<NewsStateModel>({
    name: 'news',
    defaults: {
        news: [],
        current: null,
        paginationSettings: {
            current_page: 0,
            next_page: 1,
            total_pages: undefined,
            total_items: undefined,
        },
    },
})
@Injectable()
export class NewsState {
    @Selector()
    public static news(state: NewsStateModel): INews[] {
        return state.news;
    }

    @Selector()
    public static current(state: NewsStateModel): INews {
        return state.current;
    }

    constructor(private newsService: NewsService) {}

    @Action(News.GetAll)
    public getAll(ctx: StateContext<NewsStateModel>, action: News.GetAll): Observable<IPosts> {
        const state = ctx.getState();
        if (state.paginationSettings.next_page || action.page) {
            const page = action.page || state.paginationSettings.next_page;
            return this.newsService.getAll(page).pipe(
                tap((result: IPosts) => {
                    ctx.patchState({
                        news: [...state.news, ...result.posts],
                        paginationSettings: {
                            current_page: page,
                            next_page: result.next_page,
                            total_pages: result.total_pages,
                            total_items: result.total_items,
                        },
                    });
                })
            );
        } else {
            return of(null);
        }
    }

    @Action(News.GetOne)
    public getOne(ctx: StateContext<NewsStateModel>, action: News.GetOne): Observable<INews> {
        const state = ctx.getState();
        if (state.news.length > 0) {
            const news = state.news.find((n) => n.id === action.id);
            if (news) {
                ctx.patchState({
                    current: news,
                });
                return of(news);
            }
        }
        return this.newsService.getOne(action.id).pipe(
            tap((result: INews) => {
                ctx.patchState({
                    current: result,
                });
            })
        );
    }
}
