import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Opportunities } from '../actions/opportunities.actions';
import { IOpportunities, IOpportunity } from './../../interfaces/opportunities.interface';
import { IPaginationSettings } from './../../interfaces/pagination.interface';
import { OpportunitiesService } from './../../services/opportunities.service';
import { CountriesState, CountriesStateModel } from './countries.state';

export interface OpportunitiesStateModel {
    opportunities: IOpportunity[];
    favorites: IOpportunity[];
    current: IOpportunity;
    paginationSettings: IPaginationSettings;
    sort: string;
}

@State<OpportunitiesStateModel>({
    name: 'opportunities',
    defaults: {
        opportunities: [],
        favorites: [],
        current: null,
        paginationSettings: {
            current_page: 0,
            next_page: 1,
            total_pages: undefined,
            total_items: undefined,
        },
        sort: null,
    },
})
@Injectable()
export class OpportunitiesState {
    @Selector([CountriesState])
    public static opportunities(state: OpportunitiesStateModel, countriesState: CountriesStateModel): IOpportunity[] {
        return state.opportunities.map((o) => ({ ...o, countries: countriesState.countries.filter((c) => o.countriesIds.includes(c.id)) }));
    }

    @Selector([CountriesState])
    public static current(state: OpportunitiesStateModel, countriesState: CountriesStateModel): IOpportunity {
        if (state.current) {
            return { ...state.current, countries: countriesState.countries.filter((c) => state.current.countriesIds.includes(c.id)) };
        }
        return null;
    }

    @Selector([CountriesState])
    public static favorites(state: OpportunitiesStateModel, countriesState: CountriesStateModel): IOpportunity[] {
        return state.favorites.map((o) => ({ ...o, countries: countriesState.countries.filter((c) => o.countriesIds.includes(c.id)) }));
    }

    constructor(private opportunitiesService: OpportunitiesService) {}

    @Action(Opportunities.GetOne)
    public getOne(ctx: StateContext<OpportunitiesStateModel>, action: Opportunities.GetOne): Observable<IOpportunity> {
        const state = ctx.getState();
        if (state.opportunities.length > 0) {
            const current = state.opportunities.find((o) => o.id === action.id);
            if (current) {
                ctx.patchState({
                    current: current,
                });
                return of(current);
            }
        }
        return this.opportunitiesService.getOne(action.id).pipe(
            tap((result: IOpportunity) => {
                ctx.patchState({
                    current: result,
                });
            })
        );
    }

    @Action(Opportunities.GetAll)
    public getAll(ctx: StateContext<OpportunitiesStateModel>, action: Opportunities.GetAll): Observable<IOpportunities> {
        const state = ctx.getState();
        const page = action.payload.page || state.paginationSettings.next_page;
        const sort = action.payload.sort || state.sort;
        if (page <= state.paginationSettings.total_pages || !state.paginationSettings.total_pages) {
            return this.opportunitiesService.getAll({ page: page, sort: sort }).pipe(
                tap((result: IOpportunities) => {
                    if (result.opportunities?.length > 0) {
                        ctx.patchState({
                            opportunities:
                                action.payload.page > 0 ? [...state.opportunities, ...result.opportunities] : result.opportunities,
                            paginationSettings: {
                                current_page: page,
                                next_page: result.next_page,
                                total_pages: result.total_pages,
                                total_items: result.total_items,
                            },
                        });
                        if (action.payload.sort) {
                            ctx.patchState({
                                sort: action.payload.sort,
                            });
                        }
                    }
                })
            );
        }
        return of(null);
    }

    @Action(Opportunities.GetNext)
    public getNext(ctx: StateContext<OpportunitiesStateModel>): Observable<IOpportunities> {
        const state = ctx.getState();
        return this.getAll(ctx, { payload: { page: state.paginationSettings.next_page, sort: '' } });
    }

    @Action(Opportunities.GetAllFavorites)
    public getAllFavorites(ctx: StateContext<OpportunitiesStateModel>): Observable<IOpportunity[]> {
        return this.opportunitiesService.getAllFavorites().pipe(
            tap((result: IOpportunity[]) => {
                if (result?.length > 0) {
                    ctx.patchState({
                        favorites: result,
                    });
                }
            })
        );
    }

    @Action(Opportunities.AddToFavorite)
    public addToFavorite(ctx: StateContext<OpportunitiesStateModel>, action: Opportunities.AddToFavorite): Observable<void> {
        const state = ctx.getState();
        return this.opportunitiesService.addToFavorite(action.id).pipe(
            tap(() => {
                const opportunity = state.opportunities.find((o) => o.id === action.id);
                ctx.setState(
                    patch({
                        opportunities: updateItem<IOpportunity>((o: IOpportunity) => o.id === action.id, {
                            ...opportunity,
                            bookmarked: true,
                        }),
                        favorites: updateItem<IOpportunity>((o: IOpportunity) => o.id === action.id, {
                            ...opportunity,
                            bookmarked: true,
                        }),
                    })
                );
                if (state.current?.id === action.id) {
                    ctx.patchState({
                        current: { ...opportunity, bookmarked: true },
                    });
                }
            })
        );
    }

    @Action(Opportunities.RemoveFromFavorite)
    public removeFromFavorite(ctx: StateContext<OpportunitiesStateModel>, action: Opportunities.RemoveFromFavorite): Observable<void> {
        const state = ctx.getState();
        return this.opportunitiesService.removeFromFavorite(action.id).pipe(
            tap(() => {
                const opportunity = state.opportunities.find((o) => o.id === action.id);
                ctx.setState(
                    patch({
                        opportunities: updateItem<IOpportunity>((o: IOpportunity) => o.id === action.id, {
                            ...opportunity,
                            bookmarked: false,
                        }),
                        favorites: removeItem<IOpportunity>((o: IOpportunity) => o.id === action.id),
                    })
                );
                if (state.current?.id === action.id) {
                    ctx.patchState({
                        current: { ...opportunity, bookmarked: false },
                    });
                }
            })
        );
    }

    @Action(Opportunities.Participate)
    public participate(ctx: StateContext<OpportunitiesStateModel>, action: Opportunities.Participate): Observable<IOpportunity> {
        const state = ctx.getState();
        return this.opportunitiesService.participate(action.payload.id, action.payload.alone).pipe(
            tap((result: IOpportunity) => {
                ctx.setState(
                    patch({
                        opportunities: updateItem<IOpportunity>((o: IOpportunity) => o.id === result.id, result),
                        favorites: updateItem<IOpportunity>((o: IOpportunity) => o.id === result.id, result),
                    })
                );
                if (state.current?.id === result.id) {
                    ctx.patchState({
                        current: result,
                    });
                }
            })
        );
    }

    @Action(Opportunities.ResetSort)
    public resetSort(ctx: StateContext<OpportunitiesStateModel>): void {
        ctx.patchState({
            sort: null,
        });
    }
}
