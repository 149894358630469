import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, compose, iif, patch, removeItem } from '@ngxs/store/operators';

import { IFeedbackMessage } from '../../interfaces/alert.interface';
import { FeedbackMessages } from '../actions/feedback-messages.actions';

export interface FeedbackMessageStateModel {
    messages: IFeedbackMessage[];
}

@State<FeedbackMessageStateModel>({
    name: 'feedbackMessage',
    defaults: {
        messages: [],
    },
})
@Injectable()
export class FeedbackMessageState {
    private timeOut = 6000;

    @Selector()
    public static messages(state: FeedbackMessageStateModel): IFeedbackMessage[] {
        return state.messages;
    }

    @Action(FeedbackMessages.Set)
    public setMessage(ctx: StateContext<FeedbackMessageStateModel>, payload: FeedbackMessages.Set): void {
        ctx.setState(
            patch<FeedbackMessageStateModel>({
                messages: iif<IFeedbackMessage[]>(
                    (messages: IFeedbackMessage[] | readonly IFeedbackMessage[]) => messages.length >= 3,
                    compose(removeItem(0), append([payload.message])),
                    append([payload.message])
                ),
            })
        );

        // Auto Dismiss messages
        setTimeout(() => {
            ctx.dispatch(new FeedbackMessages.Reset(ctx.getState().messages.map((message: IFeedbackMessage, index: number) => index)));
        }, this.timeOut);
    }

    @Action(FeedbackMessages.Reset)
    public resetMessage(ctx: StateContext<FeedbackMessageStateModel>, payload: FeedbackMessages.Reset): void {
        ctx.setState(
            patch<FeedbackMessageStateModel>({
                messages: iif<IFeedbackMessage[]>(
                    Array.isArray(payload.index),
                    removeItem((message: IFeedbackMessage) =>
                        (payload.index as number[]).includes(ctx.getState().messages.indexOf(message))
                    ),
                    removeItem(payload.index as number)
                ),
            })
        );
    }

    @Action(FeedbackMessages.ResetAll)
    public resetMessages(ctx: StateContext<FeedbackMessageStateModel>): void {
        ctx.patchState({
            messages: [],
        });
    }
}
