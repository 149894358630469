import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AlertTypeEnum } from '../../enums/alert.enum';
import { IFeedbackMessage } from '../../interfaces/alert.interface';
import { FeedbackMessages } from '../../store/actions/feedback-messages.actions';
import { FeedbackMessageState } from '../../store/state/feedback-message.state';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
    @Select(FeedbackMessageState.messages) public messages$: Observable<IFeedbackMessage[]>;

    /* ENUMS */
    public alertTypes = AlertTypeEnum;

    constructor(private store: Store) {}

    public removeMessage(messageId: number): void {
        this.store.dispatch(new FeedbackMessages.Reset(messageId));
    }
}
