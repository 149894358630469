import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AuthState } from '../store/state/auth.state';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    private headerConfig: any = {
        ['Content-Type']: 'application/json;charset=UTF-8',
    };

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers: HttpHeaders = Object.keys(this.headerConfig).reduce(
            (currentHeader: HttpHeaders, k: string) => currentHeader.set(k, this.headerConfig[k]),
            new HttpHeaders()
        );
        req.headers.keys().forEach((key: string) => {
            headers = headers.set(key, req.headers.get(key));
        });

        const token: string = this.store.selectSnapshot(AuthState.accessToken);
        if (token) {
            headers = headers.set('X-USER-TOKEN', token);
        }

        if (req.headers.get('Content-Type') === 'multipart/form-data') {
            headers = headers.delete('Content-Type');
        }

        const authReq: HttpRequest<any> = req.clone({ headers });
        return next.handle(authReq);
    }
}
