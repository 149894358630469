import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { SubUrlsEnum } from './../enums/sub-urls.enum';
import { IAccount } from './../interfaces/account.interface';
import { mockUser } from './mock-data';

export const mockAuthRes: any = (req: HttpRequest<any>): Observable<HttpResponse<any>> => {
    const user: IAccount = mockUser;

    if (req.method === 'POST' && req.url.includes(SubUrlsEnum.LOGIN)) {
        const res = {
            status: 200,
            body: {
                access_token: '1234',
                refresh_token: '1234',
                user: user,
            },
        };
        return of(new HttpResponse(res));
    }

    if (req.method === 'POST' && req.url.includes(SubUrlsEnum.REFRESH)) {
        const res = {
            status: 200,
            body: {
                access_token: '1234',
                refresh_token: '1234',
            },
        };
        return of(new HttpResponse(res));
    }

    if (req.method === 'POST' && req.url.includes(SubUrlsEnum.CONTACT_ME)) {
        const res = {
            status: 200,
            body: {},
        };
        return of(new HttpResponse(res));
    }

    if (req.method === 'GET' && req.url.includes(SubUrlsEnum.USER)) {
        const res = {
            status: 200,
            body: {
                user: user,
            },
        };
        return of(new HttpResponse(res));
    }

    if (req.method === 'PUT' && req.url.includes(SubUrlsEnum.USER)) {
        const res = {
            status: 200,
            body: {
                user: user,
            },
        };
        return of(new HttpResponse(res));
    }

    return of(new HttpResponse());
};
