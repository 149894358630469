<nav>
    <ul>
        <li *ngFor="let link of config">
            <a [routerLink]="[link.route] | localize" [routerLinkActive]="['is-active']">
                <div class="icon">
                    <i class="{{ 'bi ' + link.iconInactive + ' active' }}"></i>
                    <i class="{{ 'bi ' + link.iconActive + ' inactive' }}"></i>
                </div>
                <span>{{ link.title | translate }}</span>
            </a>
        </li>
    </ul>
</nav>
