import { IEnvironment } from './../shared/interfaces/environment.interface';

export const environment: IEnvironment = {
    production: false,
    api: 'https://trade4u.dev.itw.dgnus.net:8080/front',
    mock: {
        all: false,
        enable: true,
        services: {
            auth: false,
            opportunities: false,
            news: false,
            countries: false,
        },
    },
};
