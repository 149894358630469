export namespace News {
    export class GetAll {
        public static readonly type = '[news] Get all';
        constructor(public page?: number) {}
    }

    export class GetOne {
        public static readonly type = '[news] Get one';
        constructor(public id: number) {}
    }
}
